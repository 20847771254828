import React, { useState, createContext, useContext } from "react"
import PropTypes from "prop-types"

const ModalContext = createContext()
const ModalUpdateContext = createContext()

export const useModal = () => {
  return useContext(ModalContext)
}

export const useModalUpdate = () => {
  return useContext(ModalUpdateContext)
}

export const ModalProvider = ({ children }) => {
  const [isModal, setIsModal] = useState(false)

  return (
    <ModalContext.Provider value={isModal}>
      <ModalUpdateContext.Provider value={setIsModal}>
        {children}
      </ModalUpdateContext.Provider>
    </ModalContext.Provider>
  )
}

ModalProvider.propTypes = {
  children: PropTypes.node,
}
