exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-course-jsx": () => import("./../../../src/pages/course.jsx" /* webpackChunkName: "component---src-pages-course-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-lecturer-cooperation-jsx": () => import("./../../../src/pages/lecturer-cooperation.jsx" /* webpackChunkName: "component---src-pages-lecturer-cooperation-jsx" */),
  "component---src-pages-online-course-jsx": () => import("./../../../src/pages/online-course.jsx" /* webpackChunkName: "component---src-pages-online-course-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-teacher-jsx": () => import("./../../../src/pages/teacher.jsx" /* webpackChunkName: "component---src-pages-teacher-jsx" */),
  "component---src-templates-course-template-jsx": () => import("./../../../src/templates/courseTemplate.jsx" /* webpackChunkName: "component---src-templates-course-template-jsx" */),
  "component---src-templates-enterprise-case-template-jsx": () => import("./../../../src/templates/enterpriseCaseTemplate.jsx" /* webpackChunkName: "component---src-templates-enterprise-case-template-jsx" */),
  "component---src-templates-online-course-template-jsx": () => import("./../../../src/templates/onlineCourseTemplate.jsx" /* webpackChunkName: "component---src-templates-online-course-template-jsx" */),
  "component---src-templates-post-template-jsx": () => import("./../../../src/templates/postTemplate.jsx" /* webpackChunkName: "component---src-templates-post-template-jsx" */),
  "component---src-templates-service-template-jsx": () => import("./../../../src/templates/serviceTemplate.jsx" /* webpackChunkName: "component---src-templates-service-template-jsx" */),
  "component---src-templates-tag-template-jsx": () => import("./../../../src/templates/tagTemplate.jsx" /* webpackChunkName: "component---src-templates-tag-template-jsx" */),
  "component---src-templates-teacher-template-jsx": () => import("./../../../src/templates/teacherTemplate.jsx" /* webpackChunkName: "component---src-templates-teacher-template-jsx" */),
  "component---src-templates-training-news-template-jsx": () => import("./../../../src/templates/trainingNewsTemplate.jsx" /* webpackChunkName: "component---src-templates-training-news-template-jsx" */)
}

