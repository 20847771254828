/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/css/index.css"

import React from "react"

import { ModalProvider } from "./src/contexts/ModalContext"

export const wrapRootElement = ({ element }) => (
  <ModalProvider>{element}</ModalProvider>
)
